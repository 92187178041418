import React, { PropsWithChildren } from 'react'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export interface CheckoutCartProps {
  isOpen: boolean
  close: () => void
  variant?: 'persistent' | 'temporary' | 'permanent' | undefined
}

type DrawerCloseType =
  | Parameters<Exclude<DrawerProps['onClose'], undefined>>[0]
  | KeyboardEvent
  | MouseEvent
  | TouchEvent

export const CheckoutCart = (props: PropsWithChildren<CheckoutCartProps>) => {
  const handleClose = (event: DrawerCloseType) => {
    if (
      event instanceof KeyboardEvent &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    props.close()
  }

  return (
    <ClickAwayListener
      onClickAway={(e) => handleClose(e)}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <>
        <Drawer
          PaperProps={{
            elevation: 8,
            sx: {
              height: '100%',
            },
            className: 'w-[100vw]',
          }}
          className="bg-white"
          variant={props.variant}
          anchor="right"
          open={true}
          onClose={(e) => handleClose(e)}
        ></Drawer>
        <Drawer
          PaperProps={{
            elevation: 8,
            sx: {
              height: '100%',
            },
            className: 'xl:w-[500px] w-[100vw]',
          }}
          className="shadow-md w-90"
          variant={props.variant}
          anchor="right"
          open={true}
          onClose={(e) => handleClose(e)}
        >
          {props.children}
        </Drawer>
      </>
    </ClickAwayListener>
  )
}
