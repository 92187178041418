import React, { useState } from 'react'
import Image from 'next/legacy/image'
import { FINISH_CHECKOUT } from '../../utils/constants'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { CircularProgress } from '@mui/material'

const StepOne = () => {
  return (
    <>
      <h2
        className={
          'text-[2.125rem] w-[328px] font-[600] text-center mt-2 sm:mt-0 leading-[34px] tracking-[-.02em]'
        }
      >
        A few things to know
        <br />
        about checkout
      </h2>
      <div
        className={
          'h-[90%] w-[360px] flex flex-col justify-initial px-5 sm:px-0 pt-[6.5rem] sm:pt-0 sm:justify-center items-center'
        }
      >
        <div className={'flex items-center w-full'}>
          <div className={'w-[15%] sm:w-[10%] flex items-center'}>
            <Image
              alt="gift-svg"
              width={24}
              height={24}
              layout={'fixed'}
              src={'/gift.svg'}
            />
          </div>
          <span
            className={'w-[85%] sm:w-[90%] font-[16px] font-semibold sm:mx-5'}
          >
            Saga Passes will look like a gift card
          </span>
        </div>
        <div className={'flex items-center w-full mt-[30px]'}>
          <div className={'w-[15%] sm:w-[10%] flex items-center'}>
            <Image
              alt="label-svg"
              width={24}
              height={24}
              layout={'fixed'}
              src={'/label.svg'}
            />
          </div>
          <span
            className={'w-[85%] sm:w-[90%] font-[16px] font-semibold sm:mx-5'}
          >
            NFT discounts will be added to Saga
          </span>
        </div>
        <div className={'flex items-center w-full mt-[30px] '}>
          <div className={'w-[15%] sm:w-[10%] flex items-center'}>
            <Image
              alt="usdc-icon"
              width={24}
              height={24}
              layout={'fixed'}
              src={'/usdcIcon.svg'}
            />
          </div>
          <span
            className={'w-[85%] sm:w-[90%] font-[16px] font-semibold sm:mx-5'}
          >
            USDC payment can be selected on the last page of checkout
          </span>
        </div>
      </div>
    </>
  )
}

const StepTwo = () => {
  return (
    <>
      <h2
        className={
          'text-[34px] w-[328px] font-[600] text-center mt-2 sm:mt-0 leading-[34px] tracking-[-.02em]'
        }
      >
        Saga Passes will <br /> look like a gift card
      </h2>
      <div
        className={
          'h-[90%] w-[360px] flex flex-col sm:justify-center items-center'
        }
      >
        <p className={'w-[300px] lg:w-[328px] text-center my-[36px]'}>
          The $100 deposit you paid to preorder Saga will look like a gift card,
          as shown in the image below.
        </p>
        <div className={'w-full relative h-[160px]'}>
          <Image
            alt="step2-svg"
            layout={'fill'}
            src={'/checkoutSteps/step2.svg'}
          />
        </div>
      </div>
    </>
  )
}

const StepThree = () => {
  return (
    <>
      <h2
        className={
          'text-[34px] w-[328px] font-[600] text-center mt-2 sm:mt-0 leading-[34px] tracking-[-.02em]'
        }
      >
        NFT discounts will be <br /> added to Saga
      </h2>
      <div
        className={
          'h-[90%] w-[360px] flex flex-col sm:justify-center items-center'
        }
      >
        <p className={'w-[328px] text-center my-[36px] leading-5'}>
          If you have an NFT discount, it will be <br />
          applied to the Saga price in your cart.
        </p>
        <div className={'w-full relative h-[160px]'}>
          <Image
            alt="step3-svg"
            layout={'fill'}
            src={'/checkoutSteps/step3.svg'}
          />
        </div>
      </div>
    </>
  )
}

const StepFour = () => {
  return (
    <>
      <h2
        className={
          'text-[34px] w-[328px] font-[600] text-center mt-2 sm:mt-0 leading-[34px] tracking-[-.02em]'
        }
      >
        USDC payment can <br /> be selected on the <br /> last page of checkout
      </h2>
      <div
        className={
          'h-[90%] w-[360px] flex flex-col pt-[6.5rem] px-5 sm:px-0 sm:pt-0 sm:justify-center sm:px-0 pt-[8rem] sm:pt-0 items-center'
        }
      >
        <div className={'flex items-center w-full'}>
          <div
            className={'w-[15%] sm:w-[10%] flex items-center justify-center'}
          >
            <Image
              alt="one-svg"
              width={24}
              height={24}
              layout={'fixed'}
              src={'/one.png'}
            />
          </div>
          <span className={'w-[85%] sm:w-[90%] font-[16px] sm:mx-5'}>
            Select Solana Pay as payment option
          </span>
        </div>
        <div className={'flex items-center w-full mt-[30px]'}>
          <div
            className={'w-[15%] sm:w-[10%] flex items-center justify-center'}
          >
            <Image
              alt="two-svg"
              width={24}
              height={24}
              layout={'fixed'}
              src={'/two.png'}
            />
          </div>
          <span className={'w-[85%] sm:w-[90%] font-[16px] sm:mx-5'}>
            Click “complete order” in the checkout
          </span>
        </div>
        <div className={'flex items-center w-full mt-[30px] '}>
          <div
            className={'w-[15%] sm:w-[10%] flex items-center justify-center'}
          >
            <Image
              alt="three-svg"
              width={24}
              height={24}
              layout={'fixed'}
              src={'/three.png'}
            />
          </div>
          <span className={'w-[85%] sm:w-[90%] font-[16px] sm:mx-5'}>
            You’ll be automatically redirected to pay within a few seconds time.
          </span>
        </div>
      </div>
    </>
  )
}

export const PrepareCheckout = (props: {
  checkoutState: number
  onCancel: () => void
  onFinish: () => void
}) => {
  const [step, setStep] = useState(0)
  const STEPS = [StepOne, StepTwo, StepThree, StepFour]
  const activeStep = STEPS[step]
  return (
    <div
      className={
        'h-[100svh] flex justify-between sm:justify-center py-0 pb-10 sm:py-0 flex-col w-full 2xl:mt-[-10vh] max-w-[1280px] pt-[15px]'
      }
    >
      <div
        className={
          'w-full flex sm:justify-around justify-between mb-[38px] mt-2 sm:mt-0 items-center text-sm'
        }
      >
        <ArrowBack
          className={'ml-3 sm:ml-0 cursor-pointer'}
          onClick={() => {
            if (step === 0) {
              props.onCancel()
            } else {
              setStep(step - 1)
            }
          }}
        />
        <label className={'text-[13px] text-black'}>
          {step + 1} of {STEPS.length}
        </label>
        <ArrowBack className={'ml-3 sm:ml-0 cursor-pointer invisible'} />
      </div>
      <div
        className={
          'h-full sm:h-auto w-full flex justify-center sm:items-center'
        }
      >
        <div
          className={
            'max-w-[100vw] w-[560px] sm:h-[400px] rounded-xl flex flex-col items-center justify-start px-8'
          }
        >
          {activeStep()}
        </div>
      </div>

      <div className={'w-full flex flex-col items-center justify-evenly mt-3'}>
        <button
          disabled={
            step === STEPS.length - 1 && props.checkoutState !== FINISH_CHECKOUT
          }
          onClick={() => {
            if (step === STEPS.length - 1) {
              props.onFinish()
            } else {
              setStep(step + 1)
            }
          }}
          className={
            'bg-black text-white h-[45px] px-3 rounded-full  w-[328px] text-[14px] font-semibold' +
            (step === STEPS.length - 1 &&
            props.checkoutState !== FINISH_CHECKOUT
              ? 'cursor-not-allowed'
              : '')
          }
        >
          {step === STEPS.length - 1
            ? props.checkoutState === FINISH_CHECKOUT
              ? 'Ok, take me to checkout'
              : 'Please wait'
            : 'Next'}
        </button>
        <span className={'mt-[17px] text-[13px] text-black flex'}>
          {props.checkoutState === FINISH_CHECKOUT ? (
            <>
              <Image
                width={20}
                height={20}
                src={'/check_circle.svg'}
                alt="check-circle-svg"
              />{' '}
              <span className={'!mx-[13px]'}>Checkout is ready!</span>
            </>
          ) : (
            <>
              <CircularProgress size={18} className="mx-[13px] text-gray-400" />
              Preparing your checkout
            </>
          )}
        </span>
      </div>
    </div>
  )
}
