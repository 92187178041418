export const lowerCaseEquals = (string_a: string, string_b: string) => {
  return string_a.toLowerCase() === string_b.toLowerCase()
}

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const toTitleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1)
  }
  return splitStr.join(' ')
}

export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0
