import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import { OrderOverview } from '../components/Cart'
import {
  Variant,
  LineItemToAdd,
  Cart,
  CollectionWithProducts,
} from 'shopify-buy'
import { CheckoutCartProps } from '../components/Cart'
import { CheckoutCartContext } from '../context/useCheckoutCart'
import {
  addLineItems,
  createCheckout,
  updateLineItems,
  removeLineItems,
  fetchCheckout,
  VariantWithProductId,
  allowPartialAddresses,
  updateShippingAddress,
} from '../lib/shopify'
import { getAccessoriesCollection } from '../lib/shopify/collections'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  MAX_SAGA_PASS_CHECKOUT_COUNT,
  SAGA_PHONE_EU_PRODUCT_ID,
  SAGA_PHONE_US_PRODUCT_ID,
} from '../utils/constants'
import { isPhoneProduct, verifySagaQuantity } from './utils'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'
import { useInfoBox } from '../context/useInfoBox'
import { getErrorMessage } from '../lib/errors'
import useLocalStorage from '../hooks/useLocalStorage'

export interface CheckoutCartProviderProps extends CheckoutCartProps {
  children: ReactNode
}

export const CheckoutCartProvider: FC<PropsWithChildren> = ({ children }) => {
  const { enqueueInfo } = useInfoBox()
  const [visible, setVisible] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [lineItemUpdating, setLineItemUpdating] = useState('')
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [checkout, setCheckout] = useState<Cart>({} as Cart)
  const [accessories, setAccessories] = useState<CollectionWithProducts>(
    {} as CollectionWithProducts
  )
  const [productVariant, setProductVariant] = useState<VariantWithProductId>(
    {} as VariantWithProductId
  )
  const [phoneCount, setPhoneCount] = useState(0)
  const [accessoriesCount, setAccessoriesCount] = useState(0)
  const { publicKey } = useWallet()
  const { locale } = useRouter()
  // not recommended
  const didFetchAccessories = useRef(false)

  const [engravingSelected, setEngravingSelected] = useState(false)
  const [globalSelectedNft, setGlobalSelectedNft] = useLocalStorage(
    'selectedNft',
    ''
  )

  const [memorySelectedNft, setMemorySelectedNft] = useState(globalSelectedNft)

  const onSetSelectedNft = (nft: string) => {
    setMemorySelectedNft(nft)
    setGlobalSelectedNft(nft)
  }

  useEffect(() => {
    setMemorySelectedNft(globalSelectedNft)
    setEngravingSelected(!!globalSelectedNft)
  }, [globalSelectedNft])

  useEffect(() => {
    if (didFetchAccessories.current) return
    loadAccessories()
    loadCheckout(true)
    didFetchAccessories.current = true
  }, [])

  const showErrorMessage = (error: unknown) => {
    const message = getErrorMessage(
      error,
      "Checkout isn't working properly. Refresh the page and try again."
    )
    enqueueInfo(message, { variant: 'error' })
  }

  const toggleCart = useCallback(() => {
    setVisible(!visible)
  }, [visible, setVisible])

  useEffect(() => {
    if (checkout.lineItems) getProductCount()
  }, [checkout.lineItems])

  const loadCheckout = useCallback(
    async (refresh?: boolean) => {
      if (
        Object.keys(checkout).length !== 0 &&
        checkout.completedAt !== null &&
        !refresh
      )
        return
      try {
        setLoadingCheckout(true)
        const lastCheckoutResponse = await getLastCheckout()
        console.log('lastCheckoutResponse', lastCheckoutResponse)
        if (!lastCheckoutResponse.success) {
          throw new Error(lastCheckoutResponse.message)
        }

        const lastCheckout = lastCheckoutResponse.data
        let existingCheckout = lastCheckout?.isCompleted === false
        let fetchResponse
        console.log('existingCheckout', existingCheckout)
        if (existingCheckout) {
          fetchResponse = await fetchCheckout(lastCheckout.checkoutId)
          console.log(fetchResponse)
          if (
            !fetchResponse.success ||
            (fetchResponse.data &&
              (fetchResponse.data as Cart).completedAt !== null)
          ) {
            await saveCheckout(lastCheckout.checkoutId, true)
            fetchResponse = await createCheckout()
            existingCheckout = false
          }
        } else {
          console.log('creating', existingCheckout)
          fetchResponse = await createCheckout()

          console.log(fetchResponse)
        }

        if (!fetchResponse?.success) {
          throw new Error(fetchResponse?.message)
        }

        const checkout = fetchResponse.data as Cart

        if (!existingCheckout)
          await saveCheckout(checkout.id.toString(), !!checkout.order)

        setCheckout(checkout)
        return checkout as Cart
      } catch (error) {
        console.log(error)
        showErrorMessage(error)
        captureException(error)
      } finally {
        setLoadingCheckout(false)
      }
    },
    [publicKey, checkout, showErrorMessage]
  )

  const getLastCheckout = async () => {
    const response = await fetch('/api/checkout/last')
    return response.json()
  }

  const saveCheckout = async (checkoutId: string, isCompleted = false) => {
    const response = await fetch('/api/checkout/save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        checkoutId,
        isCompleted,
      }),
    })
    return response.json()
  }

  const loadAccessories = async (tryN: number = 0) => {
    const MAX_RETRIES = 3
    if (Object.keys(accessories).length === 0) {
      const response = await getAccessoriesCollection()
      if (response.success) {
        setAccessories(response.data as CollectionWithProducts)
      } else {
        // Nasty hack to retry on WkWebview for iOS
        if (tryN < MAX_RETRIES && response.message === 'Load failed') {
          loadAccessories(tryN + 1)
        } else {
          const error = new Error(`Accessories: ${response.message}`)
          showErrorMessage(error)
          captureException(error)
        }
      }
    }
  }

  const setProductVariantIfNotSet = (variant: VariantWithProductId) =>
    !productVariant?.product && !phoneCount && setProductVariant(variant)

  const loadProduct = async () => {
    if (!productVariant?.product && !phoneCount) {
      try {
        const response = await fetch('/api/shopify/get-saga', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            locale,
          }),
        })
        const result = await response.json()
        if (result.success) {
          setProductVariant(result.variantWithProductId)
        } else {
          const error = new Error(`Accessories: ${result.message}`)
          showErrorMessage(error)
          captureException(error)
        }
      } catch (error) {
        console.error('Product loading failed.')
      }
    }
  }

  const resetCheckout = async () => {
    setCheckout({} as Cart)
  }

  const getProductCount = async () => {
    const result = checkout.lineItems.reduce(
      (total, lineItem) => {
        if (isPhoneProduct(lineItem.variant.product.id.toString())) {
          total.phoneCount += lineItem.quantity
        } else {
          total.accessoriesCount += lineItem.quantity
        }
        return total
      },
      { phoneCount: 0, accessoriesCount: 0 }
    )
    setPhoneCount(result.phoneCount)
    setAccessoriesCount(result.accessoriesCount)
  }

  const checkLocaleAddress = async (
    checkoutId: any,
    lineItemProductId: string
  ) => {
    const hasSagaPhoneEurope = lineItemProductId === SAGA_PHONE_EU_PRODUCT_ID
    const hasSagaPhoneUS = lineItemProductId === SAGA_PHONE_US_PRODUCT_ID

    if (hasSagaPhoneEurope) {
      const partialAddressResponse = await allowPartialAddresses(checkoutId)
      if (!partialAddressResponse.success) {
        throw new Error(partialAddressResponse.message)
      }
      const updateAddressResponse = await updateShippingAddress(checkoutId, {
        country: 'Germany',
        city: 'Berlin',
      })
      if (!updateAddressResponse.success) {
        throw new Error(updateAddressResponse.message)
      }
    } else if (
      hasSagaPhoneUS &&
      checkout.shippingAddress &&
      //@ts-ignore
      checkout.shippingAddress.country !== 'United States'
    ) {
      const partialAddressResponse = await allowPartialAddresses(checkoutId)
      if (!partialAddressResponse.success) {
        throw new Error(partialAddressResponse.message)
      }
      const updateAddressResponse = await updateShippingAddress(checkoutId, {
        country: 'United States',
        province: 'New York',
        city: 'New York',
      })
      if (!updateAddressResponse.success) {
        throw new Error(updateAddressResponse.message)
      }
    }
  }

  const handleLineItemsToAdd = async (
    productVariant: Variant,
    qty: number,
    callback?: () => any
  ) => {
    try {
      setUpdating(true)

      if (!productVariant) throw new Error('No variant selected!')

      let provisionalCheckout: Cart | undefined = checkout

      if (Object.keys(checkout).length === 0 || checkout.completedAt !== null)
        provisionalCheckout = await loadCheckout()

      const checkoutId =
        provisionalCheckout && (provisionalCheckout.id as string)
      if (!checkoutId) throw new Error('Checkout not found')

      const isSaga = isPhoneProduct(productVariant.product?.id.toString())

      if (isSaga) {
        if (!verifySagaQuantity(phoneCount))
          throw new Error(
            ` You can only add ${MAX_SAGA_PASS_CHECKOUT_COUNT} phones`
          )
      }

      await checkLocaleAddress(checkoutId, productVariant.product?.id as string)

      const lineItem: LineItemToAdd = {
        variantId: productVariant.id,
        quantity: qty || 1,
      }
      const response = await addLineItems(checkoutId, [lineItem])

      if (!response.success) throw new Error(response.message)

      setCheckout(response.data as Cart)

      callback && callback()
    } catch (error) {
      showErrorMessage(error)
      captureException(error)
    } finally {
      setUpdating(false)
    }
  }

  const handleProductRemoval = async (lineItemId: string, message?: string) => {
    setUpdating(true)
    setLineItemUpdating(lineItemId)
    const response = await removeLineItems(checkout.id as string, [lineItemId])
    if (response.success) {
      setCheckout(response.data as Cart)
    } else {
      const error = new Error(`Remove: ${response.message}`)
      showErrorMessage(error)
      captureException(error)
    }
    setUpdating(false)
  }

  const handleUpdateQuantity = async (lineItemId: string, quantity: number) => {
    setUpdating(true)
    setLineItemUpdating(lineItemId)
    const response = await updateLineItems(checkout.id as string, [
      { id: lineItemId, quantity: quantity },
    ])
    if (response.success) {
      setCheckout(response.data as Cart)
    } else {
      const error = new Error(response.message)
      showErrorMessage(error)
      captureException(error)
    }
    setUpdating(false)
  }

  useEffect(() => {
    if (!updating) {
      setLineItemUpdating('')
    }
  }, [updating])

  return (
    <CheckoutCartContext.Provider
      value={{
        visible,
        updating,
        lineItemUpdating,
        setLineItemUpdating,
        checkout,
        accessories,
        loadingCheckout,
        setLoadingCheckout,
        setVisible,
        toggleCart,
        loadCheckout,
        resetCheckout,
        handleLineItemsToAdd,
        handleUpdateQuantity,
        handleProductRemoval,
        phoneCount,
        accessoriesCount,
        productVariant,
        setProductVariant: setProductVariantIfNotSet,
        loadProduct,
        engravingSelected,
        setEngravingSelected,
        onSetSelectedNft,
        memorySelectedNft,
      }}
    >
      {children}
      <OrderOverview
        onClose={() => setVisible(false)}
        title={'Cart'}
        isOpen={visible}
        close={() => setVisible(false)}
      />
    </CheckoutCartContext.Provider>
  )
}
