import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  DEFAULT_INFO_BOX_STATE,
  EnqueueInfoOptions,
  InfoBoxContext,
  InfoBoxInformation,
} from '../../context/useInfoBox'
import { InfoBox, InfoBoxWithChildren } from './InfoBox'

export interface InfoBoxProviderProps {
  durationVisible?: number
}

export const InfoBoxProvider: FC<PropsWithChildren<InfoBoxProviderProps>> = ({
  children,
  durationVisible = 5000,
}) => {
  const [isOpen, setOpen] = useState(false)
  const currentInfoForInfoBox = useRef<InfoBoxInformation>({ message: '' })
  const infoStack = useRef<InfoBoxInformation[]>([])

  useEffect(() => {
    let autoHideTimeoutId: NodeJS.Timeout
    const onTimeoutTimeUp = () => {
      if (infoStack.current.length) {
        currentInfoForInfoBox.current =
          infoStack.current.pop() as InfoBoxInformation
        autoHideTimeoutId = setTimeout(onTimeoutTimeUp, durationVisible)
      } else {
        setOpen(false)
      }
    }
    if (isOpen) {
      autoHideTimeoutId = setTimeout(onTimeoutTimeUp, durationVisible)
      return () => {
        clearTimeout(autoHideTimeoutId)
        setOpen(false)
      }
    }
  }, [durationVisible, isOpen])

  const enqueueInfo = useCallback(
    (message: string, options?: EnqueueInfoOptions) => {
      const nextInfo = { message, ...(options && options) }
      if (currentInfoForInfoBox.current.message === '') {
        currentInfoForInfoBox.current = nextInfo
      } else {
        infoStack.current.push(nextInfo)
      }
      setOpen(true)
    },
    []
  )

  const closeInfoBox = useCallback(() => {
    if (infoStack.current.length) {
      const lastStackMessage = infoStack.current.slice(-1)[0]
      if (
        currentInfoForInfoBox.current?.message === lastStackMessage?.message
      ) {
        infoStack.current.pop()
      }
    }
    setOpen(false)
  }, [])

  const infoBoxProviderValues = useMemo(
    () => ({
      isOpen,
      setOpen,
      currentInfo: currentInfoForInfoBox.current,
      enqueueInfo,
      closeInfoBox,
    }),
    [isOpen, enqueueInfo, closeInfoBox]
  )

  return (
    <InfoBoxContext.Provider
      value={infoBoxProviderValues || DEFAULT_INFO_BOX_STATE}
    >
      {children}
    </InfoBoxContext.Provider>
  )
}

export { InfoBox, InfoBoxWithChildren }
