export async function saveOrderReferral(orderId: string) {
  await fetch(`/api/referrals/leaderboard-save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ orderId }),
  })
}

export async function saveReferral(
  walletAddress: string,
  isBackpack: boolean = false,
  updateWallet: boolean = false
) {
  await fetch(`/api/referrals/save-referral`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ walletAddress, isBackpack, updateWallet }),
  })
}

export async function getReferral(referral: string) {
  const res = await fetch(`/api/referrals/get-referral?referral=${referral}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return res.json()
}
