import React from 'react'

const useLocalStorage = <T>(k: string, v: T) => {
  const [item, setItem] = React.useState(() => {
    if (typeof window === 'undefined') {
      return v
    }
    try {
      const data = window.localStorage.getItem(k)
      return data ? JSON.parse(data) : v
    } catch (error) {
      return v
    }
  })

  const setNewItem = (v: T) => {
    try {
      const newItem = v instanceof Function ? v(item) : v
      setItem(newItem)
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(k, JSON.stringify(newItem))
      }
    } catch (error) {
      console.log(error)
    }
  }
  return [item, setNewItem]
}

export default useLocalStorage
