import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'

export const SAGA_PASS_STATUS_ATTR = 'Saga Availability'
export const SAGA_PASS_STATUS_USED = 'Saga ordered'
export const SAGA_PASS_STATUS_UNUSED = 'Ready for order'
export const DISCOUNT_VALUE_ATTR = 'discount value'

export const MERCHANT_WALLET_ADDRESS = process.env
  .NEXT_PUBLIC_MERCHANT_WALLET_ADDRESS as string

export const USDC_TOKEN_ADDRESS = process.env
  .NEXT_PUBLIC_USDC_TOKEN_ADDRESS as string

export const SAGA_PASS_COLLECTION_ADDRESS = process.env
  .NEXT_PUBLIC_SAGA_PASS_COLLECTION_ADDRESS as string

export const MADLAD_COLLECTION_ADDRESS = process.env
  .NEXT_PUBLIC_MADLAD_COLLECTION_ADDRESS as string

export const SAGA_PASS_COLLECTION_AUTHORITY = process.env
  .SAGA_PASS_COLLECTION_AUTHORITY as string

export const SAGA_PASS_UPDATE_AUTHORITY = process.env
  .SAGA_PASS_UPDATE_AUTHORITY as string

export const SIGN_ATTRIBUTES_PRIVATE_KEY = process.env
  .SIGN_ATTRIBUTES_PRIVATE_KEY as string

export const SAGA_PHONE_US_PRODUCT_ID = process.env
  .NEXT_PUBLIC_SAGA_PHONE_US_PRODUCT_ID as string

export const SAGA_PHONE_EU_PRODUCT_ID = process.env
  .NEXT_PUBLIC_SAGA_PHONE_EU_PRODUCT_ID as string

export const SAGA_PHONE_PRODUCT_IDS = [
  SAGA_PHONE_US_PRODUCT_ID,
  SAGA_PHONE_EU_PRODUCT_ID,
]

export const SAGA_CASE_PRODUCT_ID = process.env
  .NEXT_PUBLIC_SAGA_CASE_PRODUCT_ID as string

export const SAGA_COLLECTION_ID = process.env
  .NEXT_PUBLIC_SAGA_COLLECTION_ID as string

export const SAGA_CHARGING_CABLE_ID = process.env
  .NEXT_PUBLIC_SAGA_CHARGING_CABLE_ID as string

export const MAX_SAGA_PASS_CHECKOUT_COUNT = 0

export const SHOPIFY_WEBHOOK_ENDPOINT_SECRET_KEY = process.env
  .SHOPIFY_WEBHOOK_ENDPOINT_SECRET_KEY as string

export const FEE_PAYER_PRIVATE_KEY = process.env.FEE_PAYER_PRIVATE_KEY as string

export const VERCEL_ENV = process.env.VERCEL_ENV as string

export const APP_URL = process.env.NEXT_PUBLIC_APP_URL as string

export const ORDER_EXPIRATION_MINUTES = 10

export const GCLOUD_WEBHOOK_ENDPOINT_SECRET_KEY = process.env
  .GCLOUD_WEBHOOK_ENDPOINT_SECRET_KEY as string

export const GCLOUD_LOCATION = process.env.GCLOUD_LOCATION as string

export const GCLOUD_CANCEL_ORDER_QUEUE = process.env
  .GCLOUD_CANCEL_ORDER_QUEUE as string

export const GCLOUD_UPDATE_NFT_QUEUE = process.env
  .GCLOUD_UPDATE_NFT_QUEUE as string

export const GCLOUD_CREDENTIALS = process.env.GCLOUD_CREDENTIALS as string

export const USED_TAG = 'Used'
export const UNUSED_TAG = 'Unused'

export const BUNDLR_PROVIDER = process.env.NEXT_PUBLIC_BUNDLR_PROVIDER as string

export const RPC_ENDPOINT = process.env.NEXT_PUBLIC_RPC_ENDPOINT as string

export const RPC_NETWORK = process.env
  .NEXT_PUBLIC_RPC_NETWORK as WalletAdapterNetwork

export const NOT_CHECKOUT = 0
export const START_CHECKOUT = 1
export const FINISH_CHECKOUT = 2

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string

export const SHOPIFY_MAIL_SIGNUP_URL = process.env
  .NEXT_PUBLIC_SHOPIFY_MAIL_SIGNUP_URL as string

export const TEST_MODE_ENABLED = process.env.TEST_MODE_ENABLED === 'true'

export const DISCOUNT_NFT_COLLECTION = process.env
  .DISCOUNT_NFT_COLLECTION as string

export const CIRCLE_BASE_URL = process.env.CIRCLE_BASE_URL as string
export const CIRCLE_API_KEY = process.env.CIRCLE_API_KEY as string

export const REFUND_LOCATION_ID = process.env.REFUND_LOCATION_ID as string

export const GOOGLE_TRACKING_ID = process.env
  .NEXT_PUBLIC_GOOGLE_TRACKING_ID as string
export const GOOGLE_ADS_ID = process.env.NEXT_PUBLIC_GOOGLE_ADS_ID as string
export const GOOGLE_ADS_CONVERSION_ID = process.env
  .NEXT_PUBLIC_GOOGLE_ADS_CONVERSION_ID as string

export const ITERABLE_API_KEY = process.env.ITERABLE_API_KEY as string
export const ITERABLE_LIST_ID = process.env.ITERABLE_LIST_ID as string
export const ITERABLE_ALL_CUSTOMERS_LIST_ID = process.env
  .ITERABLE_ALL_CUSTOMERS_LIST_ID as string
export const ITERABLE_WEBHOOK_TOKEN = process.env
  .ITERABLE_WEBHOOK_TOKEN as string

export const MADLAD_KEY = 'Mad Lad Engraving'
export const MADLAD_TAG = 'MADLAD'
export const ENGRAVING_TAG = 'ENGRAVING'

export const CHECKOUT_FIREBASE_COLLECTION = 'preorder-checkouts'
export const ORDER_REFERRAL_FIREBASE_COLLECTION = 'order-referrals'
export const LEADERBOARD_FIREBASE_COLLECTION = 'leaderboard'
export const REFERRALS_FIREBASE_COLLECTION = 'referrals'
export const PREORDERS_FIREBASE_COLLECTION = 'preorders'
export const CRONJOBS_FIREBASE_COLLECTION = 'cronjobs'

// Names of these collections are used to compute values in the code, update carefully
export const EMERALD_NFT_FIREBASE_COLLECTION = 'emerald-nft'
export const TANZANITE_NFT_FIREBASE_COLLECTION = 'tanzanite-nft'
export const DIAMOND_NFT_FIREBASE_COLLECTION = 'diamond-nft'
// ---

export const SNAPSHOT1_FIREBASE_COLLECTION = 'snapshot1-leaderboard'
export const SNAPSHOT2_FIREBASE_COLLECTION = 'snapshot2-live'
export const SNAPSHOT3_FIREBASE_COLLECTION = 'snapshot3-live'
export const SNAPSHOT_FIREBASE_COLLECTION = SNAPSHOT3_FIREBASE_COLLECTION
export const SNAPSHOT_JSON_FILENAME = 'snapshot3.json'

// Twitter conversion events
export const TWITTER_CONVERSION_ID = process.env
  .NEXT_PUBLIC_TWITTER_CONVERSION_ID as string
export const TWITTER_PURCHASE_EVENT = process.env
  ?.NEXT_PUBLIC_TWITTER_PURCHASE_EVENT as string

export const TWITTER_KEY = process.env.TWITTER_KEY as string
export const TWITTER_SECRET = process.env.TWITTER_SECRET as string

export const FUTURE_TIMESTAMP = 32503698000000
