import Image from 'next/image'
import { SwipeableHandlers } from 'react-swipeable'

interface ImageContainerProps {
  src: string
  priority?: boolean
  handleSwipe?: SwipeableHandlers
  roundedClass?: string
  className?: string
  nolg?: boolean
}

export const ImageContainer = ({
  src,
  priority = false,
  handleSwipe,
}: ImageContainerProps) => {
  return (
    <div {...handleSwipe} className={`w-full h-full relative overflow-hidden `}>
      <Image
        src={src}
        fill
        alt="product"
        style={{
          objectFit: 'contain',
        }}
        priority={priority}
        {...(priority && {
          quality: 100,
          sizes: '100vw',
        })}
      />
    </div>
  )
}

export const IconContainer = ({
  src,
  className,
  nolg,
}: ImageContainerProps) => {
  return (
    <div
      className={`w-[32px] h-[32px] ${
        nolg ? '' : 'lg:w-[64px] lg:h-[64px]'
      } relative inline-block align-middle overflow-hidden ${className} `}
    >
      <Image
        src={src}
        fill
        alt="icon"
        style={{
          objectFit: 'contain',
        }}
      />
    </div>
  )
}
