import { MoneyV2, Variant, Product } from 'shopify-buy'
import { IMAGE_FALLBACK, USDollar } from '../../lib/utils'
import { ImageContainer } from '../ImageContainer'
import Link from 'next/link'
import { useCallback } from 'react'
import { useCheckoutCart } from '../../context/useCheckoutCart'
import { compareGID, getHandleOrProductId } from '../../lib/shopify'
import Image from 'next/image'
import { CircularProgress } from '@mui/material'

export interface ProductProps {
  variant: Variant
}

export interface ProductImageDetailProps extends ProductProps {
  minHeight?: string
  showText?: boolean
  roundedSize?: string
  product?: Product
  showAddToCartLink?: boolean
}
type ObjectOptions = {
  [key: string]: string
}

// Tailwind trick to render classes properly
const IMAGE_HEIGHTS: ObjectOptions = {
  xs: 'min-h-[72px]',
  sm: 'min-h-[127px]',
  md: 'min-h-[226px] xl:min-h-[248px] 2xl:min-h-[380px]',
}

export const ProductImageDetail = ({
  variant,
  minHeight = 'md',
  showText,
  roundedSize = 'rounded-2xl',
  product,
}: ProductImageDetailProps) => {
  const {
    checkout,
    updating,
    handleLineItemsToAdd,
    lineItemUpdating,
    setLineItemUpdating,
    toggleCart,
  } = useCheckoutCart()

  const parsedAmount = USDollar.format(
    parseFloat(`${(variant.price as unknown as MoneyV2).amount}`)
  )

  const hasAccessoryBeenAdded = useCallback(
    (variant: Variant) => {
      return (
        checkout.lineItems &&
        checkout.lineItems.find((lineItem) => {
          return compareGID(lineItem.variant.id, variant.id)
        })
      )
    },
    [checkout.lineItems]
  )

  const handleAddAccessory = async (accessory: Product) => {
    // We assume only one variant per product
    setLineItemUpdating(variant.id as string)
    const variantToAdd: Variant = accessory.variants[0]
    variantToAdd.product = { ...accessory, variants: [] }
    if (!updating) {
      if (hasAccessoryBeenAdded(variantToAdd)) return
      await handleLineItemsToAdd(variantToAdd, 1, toggleCart)
    }
    setLineItemUpdating('')
  }

  const imageContainer = (
    <ImageContainer src={variant.image?.src || IMAGE_FALLBACK} />
  )
  const isAdded = hasAccessoryBeenAdded(variant)

  return (
    <div className="relative h-auto flex flex-col">
      <div
        className={`${roundedSize} relative ${IMAGE_HEIGHTS[minHeight]} overflow-hidden aspect-[16/10] w-full max-w-[750px]`}
      >
        {product ? (
          <Link
            href={`/detail/${getHandleOrProductId({
              product: product,
              productId: product.id,
            })}`}
            className="cursor-pointer"
          >
            {imageContainer}
          </Link>
        ) : (
          imageContainer
        )}
      </div>
      {showText ?? (
        <>
          <div className="flex flex-wrap items-stretch">
            <span className="text-base font-medium mt-5 basis-2/3">
              {product ? (
                <Link
                  href={`/detail/${getHandleOrProductId({
                    product: product,
                    productId: product.id,
                  })}`}
                  className="cursor-pointer"
                >
                  {product.title}
                </Link>
              ) : (
                variant.title
              )}
            </span>
            {product &&
              (isAdded ? (
                <div className="basis-1/3 mt-5 text-right">
                  <Image
                    src={'/circleCheckGray.svg'}
                    width={24}
                    height={24}
                    alt={'add-accessory'}
                    className={'opacity-30 inline-block'}
                  />
                  <span className="text-gray-400 pl-3">Added</span>
                </div>
              ) : updating && lineItemUpdating === variant.id ? (
                <div className="basis-1/3 mt-5 text-right">
                  <CircularProgress size={14} />
                </div>
              ) : (
                <span
                  className="cursor-pointer basis-1/3 mt-5 text-right text-[#19FB9B]"
                  onClick={() => handleAddAccessory(product)}
                >
                  Add to Cart
                </span>
              ))}
          </div>
          <span className="text-base mt-1.5">{parsedAmount}</span>
        </>
      )}
    </div>
  )
}
