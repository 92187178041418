import localFont from 'next/font/local'
import { createTheme } from '@mui/material/styles'

/**
 * Font definitions with the next/font package.
 *
 * @see https://nextjs.org/docs/api-reference/next/font
 */
export const soehne = localFont({
  src: [
    { path: './Sohne-Buch.otf', weight: '400', style: 'normal' },
    { path: './soehne-kraftig.woff2', weight: '500', style: 'normal' },
    { path: './soehne-halbfett.woff2', weight: '600', style: 'normal' },
  ],
  variable: '--font-soehne',
})

export const soehneMuiTheme = createTheme({
  typography: {
    fontFamily: soehne.style.fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: '${soehne.style.fontFamily}';
          font-style: normal;
          font-display: swap;
        }
      `,
    },
  },
})

export const ppmori = localFont({
  src: [
    // { path: 'ppmori/PPMori-Extralight.woff2', weight: '200', style: 'normal' },
    // { path: 'ppmori/PPMori-ExtralightItalic.woff2', weight: '200', style: 'italic' },
    { path: 'ppmori/PPMori-Book.woff2', weight: '400', style: 'normal' },
    // { path: 'ppmori/PPMori-BookItalic.woff2', weight: '300', style: 'italic' },
    { path: 'ppmori/PPMori-Regular.woff2', weight: '600', style: 'normal' },
    // { path: 'ppmori/PPMori-RegularItalic.woff2', weight: '400', style: 'italic' },
    // { path: 'ppmori/PPMori-SemiBold.woff2', weight: '600', style: 'normal' },
    // { path: 'ppmori/PPMori-SemiBoldItalic.woff2', weight: '600', style: 'italic' },
    // { path: 'ppmori/PPMori-ExtraBold.woff2', weight: '700', style: 'normal' },
    // { path: 'ppmori/PPMori-ExtraBoldItalic.woff2', weight: '700', style: 'italic' },
  ],
  variable: '--font-ppmori',
})
