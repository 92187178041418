import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

interface LightboxModalContextProps {
  wasOpen: boolean
  setWasOpen: (wasOpen: boolean) => void
}

const DEFAULT_CONTEXT: LightboxModalContextProps = {
  wasOpen: false,
  setWasOpen: () => {},
}

const LightboxModalContext: React.Context<LightboxModalContextProps> =
  createContext(DEFAULT_CONTEXT)

export const LightboxModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [wasOpen, setWasOpen] = useState(false)

  return (
    <LightboxModalContext.Provider value={{ wasOpen, setWasOpen }}>
      {children}
    </LightboxModalContext.Provider>
  )
}

export function useLightboxModalContext(): LightboxModalContextProps {
  return useContext(LightboxModalContext)
}
