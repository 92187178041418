import { Modal } from '@mui/material'
import { PublicKey } from '@solana/web3.js'
import Image from 'next/image'
import { soehne } from '../../fonts'
import { shortenAddress } from '../../utils/strings'
import { GreenButton } from '../GreenButton'

interface VerifyWalletModalProps {
  open: boolean
  walletPublicKey: PublicKey | null
  handleClose: () => void
  handleVerify: () => void
}

export const VerifyWalletModal = ({
  open,
  handleClose,
  walletPublicKey,
  handleVerify,
}: VerifyWalletModalProps) => {
  const handleVerifyAndClose = () => {
    handleVerify()
    handleClose()
  }

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={`${soehne.variable} sm:translate-x-[-50%] outline-none font-sans absolute bottom-0 sm:bottom-auto sm:top-[200px] sm:left-[50%] w-full sm:max-w-[360px] bg-[#1C1C1E] pt-[26px] rounded-t-[32px] sm:rounded-[32px]`}
      >
        <div
          id="modal-modal-title"
          className=" text-base flex flex-row items-center justify-center gap-3"
        >
          <Image
            src={'/buttonIcon.svg'}
            alt="lightning-icon"
            width={15}
            height={15}
          />
          <div className="">
            {shortenAddress(walletPublicKey?.toBase58() || '')}
          </div>
        </div>
        <div id="modal-modal-description" className="px-[55px] text-center ">
          <div className="mt-[48px] mb-3 text-[22px] leading-6">
            Verify this wallet belongs to you
          </div>
          <div className=" leading-5 text-sm text-[#D1D1D6]">
            We need to make sure that you are the rightful owner of this wallet
            and any of the Saga Passes in it. You will be asked to sign a
            message with your wallet.
          </div>
          <div className=" leading-5 text-sm mt-5">No SOL will be charged.</div>
        </div>
        <div className=" mt-6 mb-8 px-6">
          <GreenButton onClick={handleVerifyAndClose}>
            Verify wallet
          </GreenButton>
        </div>
      </div>
    </Modal>
  )
}
