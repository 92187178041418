/**
 * Simple Twitter Conversion Provider for easy access to the twq() function.
 */
import React, { createContext, useContext, useMemo } from 'react'
import { TWITTER_PURCHASE_EVENT } from '../utils/constants'
import { createUUID } from '../utils/conversionOrderItemUtils'

const TwitterConversionContext: React.Context<any> = createContext(null)

interface ITwitterConversionProps {
  children?: React.ReactNode
}

export enum EVENT_TYPE {
  PURCHASE = 'PURCHASE',
}

export const TwitterConversionProvider: React.FC<ITwitterConversionProps> = ({
  children,
}) => {
  // NOTE: the react-hook-consent Twitter script is not compatible with useMemo()
  // @ts-ignore
  const twqFunction = (...args) =>
    typeof window !== 'undefined' && !!(window as any).twq
      ? (window as any).twq(...args)
      : console.log('No TWQ found.')
  return (
    <TwitterConversionContext.Provider value={twqFunction}>
      {children}
    </TwitterConversionContext.Provider>
  )
}

const sentConversionEventIds = new Set<string>()

export const createTwitterConversionEvent = (
  twq: any,
  eventType: keyof typeof EVENT_TYPE
) => {
  if (!TWITTER_PURCHASE_EVENT || eventType !== EVENT_TYPE.PURCHASE) {
    return () => {}
  }
  const eventId = TWITTER_PURCHASE_EVENT

  return ({ conversionId }: { conversionId?: string }) => {
    const currentConversionId = conversionId ? conversionId : createUUID()

    if (sentConversionEventIds.has(currentConversionId)) {
      return
    }

    twq('event', eventId, {
      conversion_id: currentConversionId,
    })

    sentConversionEventIds.add(currentConversionId)
  }
}

export const useTwitterConversionEvent = (
  eventType: keyof typeof EVENT_TYPE
) => {
  const twq = useContext(TwitterConversionContext)
  const sendTwitterConversionEvent = useMemo(
    () => createTwitterConversionEvent(twq, eventType),
    [twq, eventType]
  )
  return { sendTwitterConversionEvent, twq }
}

/**
 * Example code:
 *
 *   const { sendTwitterConversionEvent } = useTwitterConversionEvent(
 *     EVENT_TYPE.PURCHASE
 *   );
 *   ...
 *   sendTwitterConversionEvent({
 *     conversionId: orderNumber,
 *   });
 */
