import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import { ProductAccessories } from './ProductAccessories'
import React, { useEffect, useState } from 'react'
import { LineItem } from 'shopify-buy'
import { ProductImageDetail } from '../Product'
import { USDollar } from '../../lib/utils'
import { Divider } from '../Divider'
import { CheckoutButtons } from './CheckoutButtons'
import { useCheckoutCart } from '../../context/useCheckoutCart'
import CircularProgress from '@mui/material/CircularProgress'
import { isPhoneProduct, verifySagaQuantity } from '../../providers/utils'
import CloseIcon from '@mui/icons-material/Close'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Drawer } from '@mui/material'
import { PrepareCheckout } from './PrepareCheckout'
import { DrawerProps } from '@mui/material/Drawer'
import { NOT_CHECKOUT, SAGA_PHONE_PRODUCT_IDS } from '../../utils/constants'

import { soehne } from '../../fonts'
import { useVerifyWallet } from '../../context/useVerifyWallet'

type DrawerCloseType =
  | Parameters<Exclude<DrawerProps['onClose'], undefined>>[0]
  | KeyboardEvent
  | MouseEvent
  | TouchEvent
export const OrderOverview = (props: {
  onClose: () => void
  title?: string
  isMobile?: boolean
  isOpen: boolean
  close: () => void
}) => {
  const {
    checkout,
    updating,
    lineItemUpdating,
    phoneCount,
    handleProductRemoval,
    handleUpdateQuantity,
  } = useCheckoutCart()
  const [productsAdded, setProductsAdded] = useState<LineItem[]>(
    checkout.lineItems || []
  )

  const { isVerifyModalOpen } = useVerifyWallet()
  useEffect(() => {
    if (checkout.lineItems) {
      setProductsAdded(checkout.lineItems)
    }
  }, [checkout.lineItems])

  const handleRemoval = async (lineItemId: string) => {
    await handleProductRemoval(lineItemId)
  }

  const handleIncrementQuantity = async (lineItem: LineItem) => {
    const newQuantity = lineItem.quantity + 1
    if (
      updating ||
      lineItemUpdating ||
      (isPhoneProduct(lineItem.variant.product.id.toString()) &&
        !verifySagaQuantity(phoneCount))
    ) {
      return
    }

    await handleUpdateQuantity(lineItem.id as string, newQuantity)
  }

  const handleDecrementQuantity = async (lineItem: LineItem) => {
    const newQuantity = lineItem.quantity - 1

    if (updating || lineItemUpdating || newQuantity === 0) {
      return
    }

    await handleUpdateQuantity(lineItem.id as string, newQuantity)
  }

  const [checkoutState, setCheckoutState] = useState(NOT_CHECKOUT)
  const [checkoutUrl, setCheckoutUrl] = useState('')
  const handleClose = (event: DrawerCloseType) => {
    if (
      isVerifyModalOpen &&
      (event instanceof MouseEvent || event instanceof TouchEvent)
    ) {
      return
    }
    if (
      event instanceof KeyboardEvent &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    props.close()
  }
  return (
    <>
      <Drawer
        PaperProps={{
          elevation: 8,
          sx: {
            height: '100%',
          },
          className:
            'w-[100vw] flex flex-col justify-center sm:justify-start items-center drawer-checkout',
        }}
        className="font-sans w-90"
        variant={'persistent'}
        anchor="right"
        open={checkoutState > NOT_CHECKOUT}
      >
        <PrepareCheckout
          checkoutState={checkoutState}
          onCancel={() => {
            setCheckoutState(NOT_CHECKOUT)
          }}
          onFinish={() => {
            location.assign(checkoutUrl)
          }}
        />
      </Drawer>
      <ClickAwayListener
        onClickAway={(e) => handleClose(e)}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Drawer
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            elevation: 8,
            sx: {
              height: '100%',
            },
            className: 'md:w-[500px] w-[100vw]',
          }}
          className={`${soehne.variable} font-sans shadow-md w-90`}
          variant={'temporary'}
          anchor="right"
          open={props.isOpen && checkoutState === 0}
          onClose={(e) => handleClose(e)}
        >
          <div className="mt-6 pl-4 pr-4 h-full relative flex flex-col">
            <div
              className={`flex ${
                props.isMobile ? 'justify-end' : 'justify-between'
              }`}
            >
              {props.title && (
                <p className="text-2xl font-bold">{props.title}</p>
              )}
              <div>
                <span
                  onClick={() => props.onClose()}
                  className="material-icons cursor-pointer"
                >
                  <CloseIcon
                    className={`${
                      props.isMobile ? 'text-sm mb-4' : 'text-2xl'
                    }`}
                  />
                </span>
              </div>
            </div>
            <div className=" flex flex-col w-full flex-grow">
              {productsAdded.length > 0 ? (
                productsAdded.map((lineItem: LineItem, indx) => {
                  const isSaga = isPhoneProduct(
                    lineItem.variant.product.id.toString()
                  )
                  const oldPrice =
                    isSaga &&
                    parseInt(`${lineItem.variant.priceV2.amount}`) < 1000
                      ? USDollar.format(
                          parseFloat(`${1000 * lineItem.quantity}`)
                        )
                      : ''
                  return (
                    <div
                      className="flex flex-row  mt-8 w-full"
                      key={`overview-lineItem-${indx}`}
                    >
                      <div className="flex-shrink-0 overflow-hidden w-[72px] rounded-xs">
                        <ProductImageDetail
                          variant={lineItem.variant}
                          showText={false}
                          minHeight={'xs'}
                          roundedSize={'rounded-md !aspect-[1/1]'}
                        />
                      </div>

                      <div className=" flex-shrink ml-3 w-40 flex flex-row justify-between flex-grow">
                        <div className="basis-3/4 font-bold flex flex-col justify-between">
                          {lineItem.title}
                          <div className="w-fit flex flex-row justify-between items-center text-md">
                            <DoNotDisturbOnOutlinedIcon
                              className={`${
                                lineItem.quantity === 1 || updating
                                  ? 'cursor-not-allowed text-gray-500'
                                  : 'cursor-pointer'
                              } mr-2`}
                              onClick={() => handleDecrementQuantity(lineItem)}
                            />
                            {lineItemUpdating === lineItem.id ? (
                              <CircularProgress size={20} />
                            ) : (
                              <span>{lineItem.quantity}</span>
                            )}
                            <ControlPointOutlinedIcon
                              onClick={() => handleIncrementQuantity(lineItem)}
                              className={`${
                                (isPhoneProduct(
                                  lineItem.variant.product.id.toString()
                                ) &&
                                  !verifySagaQuantity(phoneCount)) ||
                                updating
                                  ? 'cursor-not-allowed text-gray-500'
                                  : 'cursor-pointer'
                              } mx-2`}
                            />
                          </div>
                        </div>
                        <div className="basis-1/4 flex flex-col justify-between items-end">
                          {oldPrice && (
                            <span className="line-through">{oldPrice}</span>
                          )}
                          {USDollar.format(
                            lineItem.quantity *
                              parseFloat(lineItem.variant.priceV2.amount)
                          )}
                          <button
                            onClick={() => handleRemoval(lineItem.id as string)}
                            type="button"
                            className={`font-medium hover:text-indigo-500 ${
                              updating ? 'text-[#636366] cursor-progress' : ''
                            }`}
                            disabled={updating}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div>Start shopping, your cart is empty.</div>
              )}
              <Divider />
              {/* {<ProductAccessories />} */}
              <div className="sticky bottom-0 bg-white mt-4 flex-grow flex">
                <CheckoutButtons
                  setCheckoutState={setCheckoutState}
                  setCheckoutUrl={setCheckoutUrl}
                  isCartEmpty={
                    checkout.lineItems && checkout.lineItems.length <= 0
                  }
                />
              </div>
            </div>
          </div>
        </Drawer>
      </ClickAwayListener>
    </>
  )
}
