import {
  MAX_SAGA_PASS_CHECKOUT_COUNT,
  SAGA_PHONE_PRODUCT_IDS,
} from '../utils/constants'

export const verifySagaQuantity = (quantity: number) => {
  return quantity < MAX_SAGA_PASS_CHECKOUT_COUNT
}

export const isPhoneProduct = (productID: string) => {
  return SAGA_PHONE_PRODUCT_IDS.includes(productID)
}
