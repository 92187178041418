export class DomainError extends Error {
  error: any

  constructor(message?: string, error?: any) {
    super(message)
    this.error = error
  }
}

export const getErrorMessage = (
  error: unknown,
  defaultMessage: string = ''
) => {
  if (error instanceof DomainError) {
    return error.message
  }
  if (defaultMessage) {
    return defaultMessage
  }
  if (error instanceof Error && error.message) {
    return error.message
  }
  return 'Unknown error. Reload the page and try again.'
}
