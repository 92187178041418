import { CircularProgress } from '@mui/material'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Image from 'next/legacy/image'
import { useCheckoutCart } from '../../context/useCheckoutCart'

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    color: 'black',
    backgroundColor: 'white',
  },
}))

export const Cart = () => {
  const { loadingCheckout, toggleCart, phoneCount, accessoriesCount } =
    useCheckoutCart()
  const totalCount = phoneCount + accessoriesCount

  return (
    <button
      className="pr-[32px] flex items-center"
      onClick={() => !loadingCheckout && toggleCart()}
    >
      {loadingCheckout ? (
        <CircularProgress size={24} />
      ) : (
        <StyledBadge badgeContent={totalCount}>
          <Image
            src={'/ShoppingCart.svg'}
            alt="shopping-cart"
            width={24}
            height={24}
          />
        </StyledBadge>
      )}
    </button>
  )
}
