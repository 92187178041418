import React from 'react'
import Image from 'next/image'
import styled from '@emotion/styled'
import { ConsentBanner, useConsent } from 'react-hook-consent'

const StyledButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
`

export const GDPRConsentSettingsButton = () => {
  const { toggleDetails } = useConsent()
  return (
    <StyledButtonWrapper>
      <button onClick={toggleDetails}>
        <Image
          src={'/tools.svg'}
          alt="GDPR Settings"
          width={24}
          height={24}
          className="hover:border hover:rounded-lg"
        />
      </button>
    </StyledButtonWrapper>
  )
}

export const GDPRConsentBanner = () => {
  return (
    <>
      <ConsentBanner
        decline={{ hidden: false, label: 'Decline' }}
        settings={{ modal: { decline: { hidden: true } } }}
      >
        We use a selection of our own and third-party cookies & providers on the
        pages of this website. If you choose &quot;Approve&quot;, you consent to
        the use of all cookies. You can accept and reject individual cookie
        types and revoke your consent for the future at any time under
        &quot;Settings&quot;.
      </ConsentBanner>
      <GDPRConsentSettingsButton />
    </>
  )
}
