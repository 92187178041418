import { PropsWithChildren } from 'react'
import { useComponentHydrated } from 'react-hydration-provider'
import MediaQuery from 'react-responsive'

export const TabletOrMobileMediaQuery = ({ children }: PropsWithChildren) => {
  const hydrated = useComponentHydrated()

  return (
    <MediaQuery
      maxWidth={767}
      device={hydrated ? undefined : { deviceWidth: 767 }}
    >
      {children}
    </MediaQuery>
  )
}

export const DesktopMediaQuery = ({ children }: PropsWithChildren) => {
  const hydrated = useComponentHydrated()

  return (
    <MediaQuery
      minWidth={768}
      device={hydrated ? undefined : { deviceWidth: 768 }}
    >
      {children}
    </MediaQuery>
  )
}
