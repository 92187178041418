import React from 'react'
import { CircularProgress } from '@mui/material'

interface ButtonProps {
  children?: React.ReactNode
  disabled?: boolean
  extraClass?: string
  fullWidth?: boolean
  inverted?: boolean
  isLoading?: boolean
  onClick?: () => void
  showAsLink?: boolean
  size?: 'large' | 'small'
}

export const DiscordButton: React.FC<ButtonProps> = (props) => {
  return (
    <ButtonImpl
      {...props}
      backgroundColorClass="bg-[#5865F2]"
      borderColorClass="bg-[#5865F2]"
      disabledBackgroundColor="bg-[#2C2C2E]"
      disabledTextColor="text-[#636366]"
      linkTextColorClass="text-[#5865F2]"
      textColorClass="text-white"
    />
  )
}

export const TwitterButton: React.FC<ButtonProps> = (props) => {
  return (
    <ButtonImpl
      {...props}
      backgroundColorClass="bg-[#0F1419]"
      borderColorClass="bg-[#0F1419]"
      disabledBackgroundColor="bg-[#2C2C2E]"
      disabledTextColor="text-[#636366]"
      linkTextColorClass="text-[#0F1419]"
      textColorClass="text-white"
    />
  )
}

export const GreenButton: React.FC<ButtonProps> = (props) => {
  return (
    <ButtonImpl
      {...props}
      backgroundColorClass="bg-[#14F195]"
      borderColorClass="bg-[#14F195]"
      disabledBackgroundColor="bg-[#2C2C2E]"
      disabledTextColor="text-[#636366]"
      linkTextColorClass="text-[#14F195]"
      textColorClass="text-black"
    />
  )
}

function ButtonImpl({
  children,
  backgroundColorClass,
  borderColorClass,
  disabled = false,
  inverted,
  disabledBackgroundColor,
  disabledTextColor,
  extraClass,
  fullWidth = false,
  isLoading = false,
  linkTextColorClass,
  onClick,
  showAsLink,
  size = 'large',
  textColorClass,
}: ButtonProps &
  Readonly<{
    backgroundColorClass: string
    borderColorClass: string
    disabledBackgroundColor: string
    disabledTextColor: string
    linkTextColorClass: string
    textColorClass: string
  }>) {
  let colorClass = inverted
    ? `bg-black ${backgroundColorClass} border ${borderColorClass}`
    : `${backgroundColorClass} ${textColorClass}`

  if (disabled) {
    colorClass = `${disabledBackgroundColor} ${disabledTextColor} cursor-not-allowed`
  }

  const width = fullWidth ? 'w-full' : 'max-w-[328px]'

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={
        showAsLink
          ? linkTextColorClass
          : `${width} ${
              size === 'small' ? 'px-4 py-1' : 'px-10 py-3'
            } rounded-3xl text-base ${colorClass} ${extraClass}`
      }
    >
      {isLoading && <CircularProgress size={14} className="mr-2" />}
      {children}
    </button>
  )
}
