export const targetBlank = {
  rel: 'noopener noreferrer',
  target: '_blank',
}

export function parseLinkHeader(link: string) {
  const output: { [key: string]: string } = {}
  const regex = /<([^>]+)>; rel="([^"]+)"/g

  let m
  while ((m = regex.exec(link))) {
    const [_, v, k] = m
    output[k] = v
  }

  return output
}
