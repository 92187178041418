import { SAGA_PHONE_EU_PRODUCT_ID, SAGA_PHONE_US_PRODUCT_ID } from './constants'

const SAGA_TITLE: string = 'Saga'
const SAGA_EU_TITLE: string = 'Saga (Europe)'

export const SAGA_TITLES = [SAGA_TITLE, SAGA_EU_TITLE]

export const SAGA_IDS = {
  [SAGA_TITLE]: SAGA_PHONE_US_PRODUCT_ID,
  [SAGA_EU_TITLE]: SAGA_PHONE_EU_PRODUCT_ID,
}

interface OrderLineItems {
  node: {
    id: string
    title: string
    variant: {
      price: string
    }
    quantity: number
  }
}

export interface OrderItems {
  success: boolean
  data: {
    currencyCode: string
    totalPriceSet: {
      shopMoney: {
        amount: string
      }
    }
    lineItems: {
      edges: OrderLineItems[]
    }
  }
}

export const createUUID = () =>
  typeof crypto !== 'undefined'
    ? crypto.randomUUID()
    : (Math.random() * Number.MAX_SAFE_INTEGER).toFixed()
