/* eslint-disable @typescript-eslint/no-empty-function */
import { PublicKey } from '@solana/web3.js'
import { createContext, useContext } from 'react'

export enum VerifiedStatuses {
  Init,
  Verified,
  NoVerified,
}
export interface VerifyWalletContextState {
  verified: boolean
  isVerifying: boolean
  verifySignature: (
    publicKey: string,
    message: string,
    signature: string
  ) => Promise<any>
  saveVerifiedWallet: (wallet: string) => Promise<any>
  setIsVerifying: (isVerifying: boolean) => void
  fetchNonce: () => Promise<any>
  verifiedStatus: VerifiedStatuses
  setVerifiedStatus: (status: VerifiedStatuses) => void
  verifiedWallet?: PublicKey
  setVerifiedWallet: (publicKey: PublicKey) => void
  isVerifiedWithModal: boolean
  isVerifyModalOpen: boolean
  isVerifiedWithSource: string
  setIsVerifyModalOpen: (show: boolean) => void
  handleVerify: (noCheckoutSource?: string) => Promise<void>
}

const DEFAULT_CONTEXT: VerifyWalletContextState = {
  verified: false,
  isVerifying: false,
  verifySignature: () => Promise.resolve(),
  saveVerifiedWallet: () => Promise.resolve(),
  setIsVerifying: () => {},
  fetchNonce: () => Promise.resolve(),
  verifiedStatus: VerifiedStatuses.Init,
  setVerifiedStatus: () => {},
  setVerifiedWallet: () => Promise.resolve(),
  isVerifiedWithModal: false,
  isVerifyModalOpen: false,
  isVerifiedWithSource: '',
  setIsVerifyModalOpen: () => {},
  handleVerify: async () => {},
}

export const VerifyWalletContext = createContext<VerifyWalletContextState>(
  DEFAULT_CONTEXT as VerifyWalletContextState
)

export function useVerifyWallet(): VerifyWalletContextState {
  return useContext(VerifyWalletContext)
}
