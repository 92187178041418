import { ReactNode } from 'react'

interface OrderButtonProps {
  content: ReactNode
  color: string
  id: string
  background: string
  onClick?: () => void
  disabled?: boolean
}

export const OrderButton = (props: OrderButtonProps) => {
  return (
    <button
      id={props.id}
      onClick={() => props.onClick && props.onClick()}
      disabled={props.disabled}
      className={`py-2 px-2 ${props.background} rounded-3xl ${props.color} font-semibold mt-5`}
    >
      {props.content}
    </button>
  )
}
