import Image from 'next/image'
import React, { FC, PropsWithChildren, useEffect } from 'react'
import { AnimatePresence, LazyMotion, m } from 'framer-motion'
import {
  EnqueueInfoOptions,
  InfoBoxProps,
  InfoVariantType,
  useInfoBoxInternal,
} from '../../context/useInfoBox'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  DesktopMediaQuery,
  TabletOrMobileMediaQuery,
} from '../../components/ResponsiveWrappers'
import CloseOutlined from '@mui/icons-material/CloseOutlined'

interface InfoBoxWrapperProps {
  $forceSnackbar?: boolean
  $forceSnackbarTop?: boolean
}

const StyledInfoBoxPlacementWrapper = styled.div<InfoBoxWrapperProps>`
  position: fixed;
  right: 0.5rem;
  bottom: 2rem;
  left: 0.5rem;
  height: fit-content;
  z-index: 50;

  @media (min-width: 768px) {
    position: relative;
    z-index: auto;
    ${(props) =>
      props.$forceSnackbar || props.$forceSnackbarTop
        ? css`
            position: fixed;
            max-width: 1980px;
            z-index: 50;
          `
        : ``}
    ${(props) =>
      props.$forceSnackbar
        ? css`
            right: 0.5rem;
            bottom: 2rem;
            left: 0.5rem;
          `
        : ``}
    ${(props) =>
      props.$forceSnackbarTop
        ? css`
            right: 4.7875rem;
            top: 3.5rem;
            left: 4.7875rem;
          `
        : ``}
`

const animationConfiguration = {
  hidden: { opacity: 0, x: 0, y: -100 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 100 },
}

export const TransitionInOut = ({
  children,
  isOpen = false,
}: {
  children: React.ReactNode
  isOpen: boolean
}) => (
  <LazyMotion
    features={() => import('./features.js').then((res) => res.default)}
  >
    <AnimatePresence initial={false}>
      {isOpen && (
        <m.div
          variants={animationConfiguration}
          initial="hidden"
          animate="enter"
          exit="exit"
          transition={{ type: 'linear' }}
        >
          {children}
        </m.div>
      )}
    </AnimatePresence>
  </LazyMotion>
)

export const InfoBoxWithChildren: FC<PropsWithChildren<InfoBoxProps>> = ({
  children,
  className,
  boxClassName,
  icon,
  visibilityCondition,
  forceSnackbar = false,
  forceSnackbarTop = false,
}) => {
  const { isOpen, setOpen, closeInfoBox } = useInfoBoxInternal()

  useEffect(() => {
    if (visibilityCondition) {
      setOpen(true)
    }
  }, [setOpen, visibilityCondition])

  return (
    <StyledInfoBoxPlacementWrapper
      $forceSnackbar={forceSnackbar}
      $forceSnackbarTop={forceSnackbarTop}
    >
      <TransitionInOut isOpen={isOpen}>
        <div
          className={`flex justify-center mt-5 w-full ${
            className || `text-black`
          }`}
        >
          <div
            className={`flex flex-row gap-2.5 rounded-xl w-full items-start md:items-center px-3 xl:px-[26px] py-[15px] ${
              boxClassName || 'bg-white border border-[#8E8E93]'
            }`}
          >
            <Image
              src={icon || `/infoGray.svg`}
              width={24}
              height={24}
              alt="info-icon"
              className="text-black"
            />
            <span className="flex flex-col xl:flex-row items-start xl:items-center justify-between gap-[5px] text-sm grow">
              <span>{children}</span>
            </span>
            <div
              className={`justify-self-end cursor-pointer text-sm ${
                className || `text-black`
              }`}
              onClick={() => closeInfoBox()}
            >
              <CloseOutlined fontSize="small" />
            </div>
          </div>
        </div>
      </TransitionInOut>
    </StyledInfoBoxPlacementWrapper>
  )
}

const InfoWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="lg:flex lg:justify-center lg:w-full">
      <div>{children}</div>
    </div>
  )
}

export const processVariant = (variant?: InfoVariantType) => {
  switch (variant) {
    case 'success':
      return {
        className: 'text-[#6941C6]',
        icon: '/infoVerified.svg',
      }
    case 'fallout':
      return {
        className: 'text-[#B54708]',
        boxClassName: 'bg-[#FFFCF5] border border-[#FEC84B]',
        icon: '/alert.svg',
      }
    case 'error':
    case 'warning':
    case 'info':
    case 'default':
    default:
      return {}
  }
}

export const SHOW_SAGA_PASS_INFO_TEXTS = [
  'You need a Saga Pass to add items to cart.',
  'You can start adding items to your cart.',
]

export const InfoBox: FC<PropsWithChildren<InfoBoxProps>> = ({
  className,
  forceSnackbar = false,
  forceSnackbarTop = false,
}) => {
  const { currentInfo } = useInfoBoxInternal()
  const { message, options = {} } = currentInfo
  const { icon, variant } = options as EnqueueInfoOptions
  const {
    className: variantClassName,
    boxClassName,
    icon: variantIcon,
  } = processVariant(variant)
  const isVisible = message !== ''
  return (
    <InfoBoxWithChildren
      className={className || variantClassName}
      boxClassName={boxClassName}
      icon={icon || variantIcon}
      visibilityCondition={isVisible}
      forceSnackbar={forceSnackbar}
      forceSnackbarTop={forceSnackbarTop}
      key={message}
    >
      <InfoWrapper>{message}</InfoWrapper>
    </InfoBoxWithChildren>
  )
}

export const GlobalInfoBox: FC = () => {
  return (
    <>
      <DesktopMediaQuery>
        <InfoBox forceSnackbarTop={true} />
      </DesktopMediaQuery>
      <TabletOrMobileMediaQuery>
        <InfoBox forceSnackbar={true} />
      </TabletOrMobileMediaQuery>
    </>
  )
}
