/**
 * Simple Google Analytics provider for easy access to the ga() function.
 */

import React, { createContext, useContext, useMemo } from 'react'
import {
  createUUID,
  OrderItems,
  SAGA_TITLES,
} from '../utils/conversionOrderItemUtils'
import { GOOGLE_ADS_CONVERSION_ID } from '../utils/constants'

const gaContext: React.Context<any> = createContext(null)

export const GaProvider: React.Provider<() => void | null> = gaContext.Provider

export const useGa = () => useContext(gaContext)

export function throwIfSSR() {
  throw new Error('Using GA during SSR is not allowed')
}

export function gaHandler() {
  // @ts-ignore
  const dataLayer = (window.dataLayer = window.dataLayer || [])

  dataLayer.push(arguments)
}

export const useGaHandler = () => {
  return typeof window === 'undefined' ? throwIfSSR : gaHandler
}

const sentGaConversionEventIds = new Set<string>()

export const createGaConversionEvent = (ga: any) => {
  if (!GOOGLE_ADS_CONVERSION_ID) {
    return () => {}
  }

  const generatedConversionId = createUUID()

  return ({
    conversionId,
    orderItems,
  }: {
    conversionId?: string
    orderItems: OrderItems
  }) => {
    let orderlineItems = orderItems.data.lineItems
    if (
      orderlineItems.edges.some((checkoutLineItem) =>
        SAGA_TITLES.includes(checkoutLineItem.node.title)
      )
    ) {
      const currentConversionId = conversionId
        ? conversionId
        : generatedConversionId

      if (
        sentGaConversionEventIds.has(currentConversionId) ||
        !orderItems.success
      ) {
        return
      }

      // @ts-ignore
      ga('event', 'conversion', {
        send_to: GOOGLE_ADS_CONVERSION_ID,
        value: orderItems.data.totalPriceSet.shopMoney.amount,
        currency: orderItems.data.currencyCode,
        transaction_id: conversionId,
      })

      sentGaConversionEventIds.add(currentConversionId)
    }
  }
}

export const useGaConversionEvent = () => {
  const ga = useGaHandler()
  const sendGaConversionEvent = useMemo(() => createGaConversionEvent(ga), [ga])
  return { sendGaConversionEvent }
}

/**
 * Example code:
 *
 *   const { sendGaConversionEvent } = useGaConversionEvent();
 *   ...
 *   sendGaConversionEvent({
 *     conversionId: orderNumber,
 *     orderItems,
 *   });
 */
