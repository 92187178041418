/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'
import { Cart, CollectionWithProducts, Variant } from 'shopify-buy'
import { VariantWithProductId } from '../lib/shopify'

export interface CheckoutCartContextState {
  visible: boolean
  updating: boolean
  engravingSelected: boolean
  lineItemUpdating: string
  setLineItemUpdating: (lineItemUpdating: string) => void
  checkout: Cart
  accessories: CollectionWithProducts
  loadingCheckout: boolean
  phoneCount: number
  accessoriesCount: number
  productVariant: VariantWithProductId
  setEngravingSelected: (engravingSelected: boolean) => void
  setProductVariant: (productWithId: VariantWithProductId) => void
  loadProduct: () => void
  setLoadingCheckout: (loading: boolean) => void
  setVisible: (open: boolean) => void
  toggleCart: () => void
  loadCheckout: (refresh?: boolean) => void
  resetCheckout: () => void
  handleLineItemsToAdd: (
    variant: Variant,
    quantity: number,
    callback?: () => void
  ) => void
  handleUpdateQuantity: (lineItemId: string, newQuantity: number) => void
  handleProductRemoval: (lineItemId: string) => void
  onSetSelectedNft: (nft: string) => void
  memorySelectedNft: string
}

const DEFAULT_CONTEXT = {
  visible: false,
  updating: false,
  engravingSelected: false,
  lineItemUpdating: '',
  checkout: {} as Cart,
  accessories: {} as CollectionWithProducts,
  loadingCheckout: false,
  phoneCount: 0,
  accessoriesCount: 0,
  productVariant: {} as VariantWithProductId,
  memorySelectedNft: '',
  setProductVariant: () => {},
  setLineItemUpdating: () => {},
  loadProduct: () => {},
  setLoadingCheckout: () => {},
  setVisible: () => {},
  toggleCart: () => {},
  loadCheckout: () => {},
  resetCheckout: () => {},
  handleLineItemsToAdd: () => {},
  handleUpdateQuantity: () => {},
  handleProductRemoval: () => {},
  setEngravingSelected: () => {},
  onSetSelectedNft: () => {},
}
export const CheckoutCartContext = createContext<CheckoutCartContextState>(
  DEFAULT_CONTEXT as CheckoutCartContextState
)

export function useCheckoutCart(): CheckoutCartContextState {
  return useContext(CheckoutCartContext)
}
