import { PublicKey } from '@solana/web3.js'
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  VerifiedStatuses,
  VerifyWalletContext,
} from '../context/useVerifyWallet'
import { VerifyWalletModal } from '../components/VerifyWalletModal'
import { DomainError, getErrorMessage } from '../lib/errors'
import bs58 from 'bs58'
import { useWallet } from '@solana/wallet-adapter-react'
import { useInfoBox } from '../context/useInfoBox'
import { WalletError } from '@solana/wallet-adapter-base'
import { captureException } from '@sentry/nextjs'

export const VerifyWalletProvider: FC<PropsWithChildren> = ({ children }) => {
  const { publicKey, signMessage } = useWallet()
  const { enqueueInfo } = useInfoBox()

  const [isVerifying, setIsVerifying] = useState(false)
  const [verifiedStatus, setVerifiedStatus] = useState<VerifiedStatuses>(
    VerifiedStatuses.Init
  )
  const [verifiedWallet, setVerifiedWallet] = useState<PublicKey>()
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [isVerifiedWithModal, setIsVerifiedWithModal] = useState(false)
  const [isVerifiedWithSource, setIsVerifiedWithSource] = useState('')

  const fetchNonce = async () => {
    const response = await fetch('/api/nonce')
    return response.json()
  }

  const handleVerify = async (noCheckoutSource?: string) => {
    try {
      setVerifiedStatus(VerifiedStatuses.Init)
      setIsVerifying(true)

      if (!publicKey) {
        throw new DomainError(
          'Your wallet is not connected. Please try connecting it again.'
        )
      }
      if (!signMessage) {
        throw new DomainError(
          "This wallet isn't allowed to sign messages. Try connecting with a different wallet."
        )
      }
      if (
        !verifiedWallet ||
        publicKey.toBase58() !== verifiedWallet.toBase58()
      ) {
        const nonceResponse = await fetchNonce()
        if (!nonceResponse.success) throw new Error(nonceResponse.message)

        const nonce = nonceResponse.nonce
        const message = `Sign this message to verify your wallet.\n\nNonce: ${nonce}`
        const encodedMessage = new TextEncoder().encode(message)
        const signature = await signMessage(encodedMessage)

        const verifyResponse = await verifySignature(
          publicKey.toBase58(),
          message,
          bs58.encode(signature)
        )
        if (!verifyResponse.success) throw new Error(verifyResponse.message)

        const saveResponse = await saveVerifiedWallet(publicKey.toBase58())
        if (!saveResponse.success) throw new Error(saveResponse.message)

        setVerifiedWallet(publicKey)
        setVerifiedStatus(VerifiedStatuses.Verified)
        if (!noCheckoutSource) {
          setIsVerifiedWithModal(true)
        } else {
          setIsVerifiedWithSource(noCheckoutSource)
        }
      } else if (publicKey.toBase58() === verifiedWallet.toBase58()) {
        setVerifiedStatus(VerifiedStatuses.Verified)
      }
    } catch (error) {
      if (!(error instanceof WalletError)) {
        const message = getErrorMessage(
          error,
          "We couldn't verify your wallet. Please try again."
        )
        enqueueInfo(message, { variant: 'error' })
        setVerifiedStatus(VerifiedStatuses.NoVerified)
        captureException(error)
      }
    } finally {
      setIsVerifying(false)
    }
  }

  const verifySignature = async (
    publicKey: string,
    message: string,
    signature: string
  ) => {
    const response = await fetch('/api/verify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ publicKey, message, signature }),
    })
    return response.json()
  }

  const saveVerifiedWallet = async (wallet: string) => {
    const response = await fetch('/api/save-verified-wallet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        wallet: wallet,
      }),
    })
    return response.json()
  }

  useEffect(() => {
    if (!publicKey) return

    setVerifiedStatus(VerifiedStatuses.Init)
    if (verifiedWallet && publicKey.toBase58() === verifiedWallet.toBase58()) {
      setVerifiedStatus(VerifiedStatuses.Verified)
    }
  }, [publicKey, verifiedWallet, setVerifiedStatus])

  const verified = useMemo(
    () => verifiedStatus == VerifiedStatuses.Verified,
    [verifiedStatus]
  )

  return (
    <VerifyWalletContext.Provider
      value={{
        verified,
        isVerifying,
        setIsVerifying,
        verifySignature,
        fetchNonce,
        verifiedStatus,
        setVerifiedStatus,
        saveVerifiedWallet,
        verifiedWallet,
        setVerifiedWallet,
        isVerifiedWithModal,
        isVerifyModalOpen,
        setIsVerifyModalOpen,
        isVerifiedWithSource,
        handleVerify,
      }}
    >
      <VerifyWalletModal
        open={isVerifyModalOpen}
        handleClose={() => setIsVerifyModalOpen(false)}
        walletPublicKey={publicKey}
        handleVerify={handleVerify}
      />
      {children}
    </VerifyWalletContext.Provider>
  )
}
