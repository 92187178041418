import { GraphModel, LineItem, Product, Variant } from 'shopify-buy'
import {
  SAGA_CHARGING_CABLE_ID,
  SAGA_PHONE_EU_PRODUCT_ID,
  SAGA_PHONE_PRODUCT_IDS,
  SAGA_PHONE_US_PRODUCT_ID,
} from '../../utils/constants'

export const parseShopifyResponse = (
  response: GraphModel | GraphModel[] | VariantWithProductId | Variant
) => JSON.parse(JSON.stringify(response))

export const parseProductId = (productId: string | number) => {
  if (typeof productId === 'number') return productId
  return productId.replace('gid://shopify/Product/', '')
}
export const parseCheckoutId = (productId: string | number) => {
  if (typeof productId === 'number') return productId
  return productId.replace('gid://shopify/Checkout/', '')
}

export const getHandleOrProductId = ({
  product,
  productId,
}: {
  product?: Product
  productId: string | number
}) => {
  if (!product?.handle) {
    return parseProductId(productId)
  }
  return product.handle
}

export const parseVariantId = (productId: string | number) => {
  if (typeof productId === 'number') return productId
  return productId.replace('gid://shopify/ProductVariant/', '')
}

export const parseOrderId = (orderId: string | number) => {
  if (typeof orderId === 'number') return orderId
  return orderId.replace('gid://shopify/Order/', '')
}

export const compareGID = (gidA: string | number, gidB: string | number) => {
  const formatGID = (gid: string | number) => {
    if (typeof gid === 'number') return gid.toString()
    return gid.split('/').pop() || ''
  }
  return formatGID(gidA) === formatGID(gidB)
}

export interface APIError {
  message: string
}

export const getResponse = <T>(response: T) => {
  if (response) {
    return {
      success: true,
      data: response as T,
    }
  }
  return {
    success: false,
    message: 'An unexpected error has occurred with the Cart',
  }
}

export const getProductIdByMarket = (market: string) => {
  switch (market) {
    case 'us':
      return SAGA_PHONE_US_PRODUCT_ID
    case 'eu':
      return SAGA_PHONE_EU_PRODUCT_ID
    default:
      return SAGA_PHONE_US_PRODUCT_ID
  }
}

export const filterSagaVariantByMarket = (
  products: Product[],
  market: string
): VariantWithProductId => {
  const filteredProduct = products.filter((product: Product) => {
    return product.id === getProductIdByMarket(market)
  })[0]

  if (!filteredProduct) {
    throw new Error('No product found for market')
  }

  return {
    productId: filteredProduct.id.toString(),
    variant: filteredProduct.variants[0],
    product: filteredProduct,
  }
}
export const parsePreorderToVariant = (
  products: Product[]
): VariantWithProductId => {
  const filteredProduct = products[0]

  if (!filteredProduct) {
    throw new Error('No product found for preorder')
  }

  return {
    productId: filteredProduct.id.toString(),
    variant: filteredProduct.variants[0],
    product: filteredProduct,
  }
}

export interface VariantWithProductId {
  productId: string
  variant: Variant
  product: Product
}

export const sagaCountInCheckout = (lineItems: LineItem[]) =>
  lineItems?.reduce((acc, item) => {
    let addition = 0
    if (SAGA_PHONE_PRODUCT_IDS.includes(item.variant.product.id.toString())) {
      addition = item.quantity
    }
    return acc + addition
  }, 0)

export const filterCableFromAccessories = (accessoriesProducts: Product[]) => {
  if (accessoriesProducts.length) {
    return accessoriesProducts.filter(
      (product) => parseProductId(product.id) !== SAGA_CHARGING_CABLE_ID
    )
  }
  return accessoriesProducts
}
